import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import TimeInput, { formatUtc } from 'Components/inputs/Time';
import Toggle, { normalizeBoolean } from 'Components/inputs/Toggle';
import TextEdit from 'Components/inputs/TextEdit';

export class GlobalConfigurationForm extends Component {
  config = {
    fields: [
      'id',
      'activeLiveTransfer',
      'liveTransferMondayStartHour',
      'liveTransferMondayEndHour',
      'liveTransferTuesdayStartHour',
      'liveTransferTuesdayEndHour',
      'liveTransferWednesdayStartHour',
      'liveTransferWednesdayEndHour',
      'liveTransferThursdayStartHour',
      'liveTransferThursdayEndHour',
      'liveTransferFridayStartHour',
      'liveTransferFridayEndHour',
      'liveTransferSaturdayStartHour',
      'liveTransferSaturdayEndHour',
      'liveTransferSundayStartHour',
      'liveTransferSundayEndHour',
      'articleFooterText',
      'adsTxt',
      'appAdsTxt',
      'isFocusBlockMercatoSurveyEnabled',
    ],
    disposition: [
      {
        fields: [
          {
            name: 'activeLiveTransfer',
            component: Toggle,
            normalize: normalizeBoolean,
          },
        ],
      },
      {
        size: 2,
        fields: [
          {
            name: 'liveTransferMondayStartHour',
            component: TimeInput,
            normalize: formatUtc,
          },
          {
            name: 'liveTransferTuesdayStartHour',
            component: TimeInput,
            normalize: formatUtc,
          },
          {
            name: 'liveTransferWednesdayStartHour',
            component: TimeInput,
            normalize: formatUtc,
          },
          {
            name: 'liveTransferThursdayStartHour',
            component: TimeInput,
            normalize: formatUtc,
          },
          {
            name: 'liveTransferFridayStartHour',
            component: TimeInput,
            normalize: formatUtc,
          },
          {
            name: 'liveTransferSaturdayStartHour',
            component: TimeInput,
            normalize: formatUtc,
          },
          {
            name: 'liveTransferSundayStartHour',
            component: TimeInput,
            normalize: formatUtc,
          },
        ],
      },
      {
        size: 2,
        fields: [
          {
            name: 'liveTransferMondayEndHour',
            component: TimeInput,
            normalize: formatUtc,
          },
          {
            name: 'liveTransferTuesdayEndHour',
            component: TimeInput,
            normalize: formatUtc,
          },
          {
            name: 'liveTransferWednesdayEndHour',
            component: TimeInput,
            normalize: formatUtc,
          },
          {
            name: 'liveTransferThursdayEndHour',
            component: TimeInput,
            normalize: formatUtc,
          },
          {
            name: 'liveTransferFridayEndHour',
            component: TimeInput,
            normalize: formatUtc,
          },
          {
            name: 'liveTransferSaturdayEndHour',
            component: TimeInput,
            normalize: formatUtc,
          },
          {
            name: 'liveTransferSundayEndHour',
            component: TimeInput,
            normalize: formatUtc,
          },
        ],
      },
      {
        fields: [
          {
            name: 'isFocusBlockMercatoSurveyEnabled',
            component: Toggle,
            normalize: normalizeBoolean,
          },
        ],
      },
      {
        fields: [
          {
            name: 'articleFooterText',
            component: TextEdit,
          },
        ],
      },
      {
        fields: [
          {
            name: 'adsTxt',
            component: TextEdit,
          },
          {
            name: 'appAdsTxt',
            component: TextEdit,
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`configuration_global_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
      />
    );
  }
}
